import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Stack from 'react-bootstrap/Stack';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function ContactPage() {
  return (
    <StyledSection>
      <Container>
          <Row>
              <Col>
                  <Card bg='trans-white'>
                    <Card.Body>
                      <Card.Title><h1 className='blue-color'>Contact Information - RES Luxury Group top producing eXp Realty team for your luxury property needs.</h1></Card.Title>
                      <Card.Subtitle><h2>Serving Citrus County, Florida and surrounding areas contact us today!</h2></Card.Subtitle>
                      <Card.Text as='div'>
                        <p><b>Value Proposition:</b> RES Luxury Group is a team of market experts committed to achieving the best buying & selling results. We utilize strong negotiation skills and marketing strategies which we are continuously adapting to compliment the current market cycle.</p>
                        <p><b>Mission Statement:</b> RES Luxury Group strives to raise the bar of expectations for the Real Estate industry through innovative ideas & technologies that allow us to better serve the dynamic needs of today’s consumer. We push ourselves daily to achieve new levels of excellence for our customers.</p>
                        <Row>
                          <Col xs={8}>
                            <Stack gap={2}>
                              <h3><strong>RES Luxury Group, eXp Realty: </strong> <a href='mailto:Homes@RESLuxuryGroup.com?subject=Website%20Contact' aria-label='Click to email RES Luxury Group, LLC' title='Click to email RES Luxury Group, LLC'>Homes@RESLuxuryGroup.com</a></h3>
                              <h3><strong>Phone:</strong> <a href='tel:+13527941426' aria-label='Click to automatically dial from mobile device' title='Click to automatically dial from mobile device'>352.794.1426</a></h3>
                            </Stack>
                          </Col>
                          <Col>
                            <Stack gap={2}>
                              <h3><strong>Facebook:</strong> <a href='https://www.facebook.com/RESLuxuryGroup' target='_blank' rel='noopener' aria-label='Like Ruth Squires - RES Luxury Group, eXp Realty on Facebook' title='Like Ruth Squires - RES Luxury Group, eXp Realty'>RESLuxuryGroup</a></h3>
                              <h3><strong>Twitter:</strong> <a href='https://www.twitter.com/&#64;realtorcitrus' target='_blank' rel='noopener' aria-label='Follow Ruth Squires - RES Luxury Group, eXp Realty on Twitter' title='Follow Ruth Squires - RES Luxury Group, eXp Realty on Twitter'>&#64;realtorcitrus</a></h3>
                              <h3><strong>Instagram:</strong> <a href='https://www.instagram.com/RESLuxuryGroup' target='_blank' rel='noopener' aria-label='Follow Ruth Squires - RES Luxury Group, eXp Realty on Instagram' title='Follow Ruth Squires - RES Luxury Group, eXp Realty on Instagram'>RESLuxuryGroup</a></h3>
                              <h3><strong>LinkedIn:</strong> <a href='https://www.linkedin.com/in/ruthsquiresrealtorrealtor' target='_blank' rel='noopener' aria-label='Connect with Ruth Squires - RES Luxury Group, eXp Realty on LinkedIn' title='Connect with Ruth Squires - RES Luxury Group, eXp Realty on LinkedIn'>RuthSquiresRealtor</a></h3>
                              <h3><strong>YouTube:</strong> <a href='https://www.youtube.com/realtorcitrus' target='_blank' rel='noopener' aria-label='Watch Ruth Squires - RES Luxury Group, eXp Realty on YouTube' title="Luxury home for sale in Pine Ridge Estates, Citrus County FL. House for Sale in equestrian and golf community in FL. Pool home for sale in Citrus County FL. Beautiful community, great buy, florida living at it's finest!">realtorcitrus</a></h3>
                            </Stack>
                          </Col>
                        </Row>
                        <Row className='align-items-center py-4'>
                          <Col md={12} lg={4} xxl={3}>
                            <img src='/assets/images/Ruth-Squires.png' width='300' alt='Ruth Squires - Luxury Listing Specialist' />
                          </Col>
                          <Col md={12} lg={8} xxl={9}>
                            <Stack gap={1}>
                              <h4 className='blue-color'>Ruth Squires, eXp Realty - Luxury Listing Specialist</h4>
                              <span><strong>Email:</strong> <a href='mailto:Ruth@RESLuxuryGroup.com?subject=Website%20Contact' aria-label='Click to email Ruth Squires of RES Luxury Group, LLC' title='Click to email Ruth Squires of RES Luxury Group, LLC'>Ruth@RESLuxuryGroup.com</a></span>
                              <span><strong>Phone:</strong> <a href='tel:+13524763303' aria-label='Click to automatically dial Ruth Squires from mobile device' title='Click to automatically dial Ruth Squires from mobile device'>352.476.3303</a></span>
                              <span><strong>LinkedIn:</strong> <a href='https://www.linkedin.com/in/ruthsquiresrealtor' target='_blank' rel='noopener' aria-label='Connect with Ruth Squires, Realtor® eXp Realty on LinkedIn' title='Connect with Ruth Squires, Realtor® eXp Realty on LinkedIn'>ruthsquires</a></span>
                              <span><strong>Bio:</strong> With over a decade of experience in the financial industry, I transitioned to a career in real estate in 2015. My knowledge of insurance, investments, and taxes has proven invaluable in this rewarding field. 
                              In 2017, I founded RES Luxury Group, a highly successful team that has outperformed the market average for sellers by 3-4%. In 2020, we ranked #6 among agents in the county and are striving for the top spot in the future. 
                              Thank you to all who have supported us on this journey.</span>
                            </Stack>
                          </Col>
                        </Row>
                        <Row className='align-items-center py-4'>
                          <Col md={12} lg={4} xxl={3}>
                            <img src='/assets/images/Irene-Snelgrove.png' width='300' alt='Irene Snelgrove - Luxury Buyer Specialist' />
                          </Col>
                          <Col md={12} lg={8} xxl={9}>
                            <Stack gap={1}>
                              <h4 className='blue-color'>Irene Snelgrove, eXp Realty - Luxury Buyer Specialist</h4>
                              <span><strong>Email:</strong> <a href='mailto:Irene@RESLuxuryGroup.com?subject=Website%20Contact' aria-label='Click to email Irene Snelgrove of RES Luxury Group, LLC' title='Click to email Irene Snelgrove of RES Luxury Group, LLC'>Irene@RESLuxuryGroup.com</a></span>
                              <span><strong>Phone:</strong> <a href='tel:+13524544643' aria-label='Click to automatically dial Irene Snelgrove from mobile device' title='Click to automatically dial Irene Snelgrove from mobile device'>352.454.4643</a></span>
                              <span><strong>Bio:</strong> I have been a REALTOR® in Florida since 2001 and obtained my Brokers license in 2015. I understand the diverse market we have in Florida and continuously educate myself to adjust to the changes in our market.</span>
                            </Stack>
                          </Col>
                        </Row>            
                        <Row className='align-items-center py-4'>
                          <Col md={12} lg={4} xxl={3}>
                            <img src='/assets/images/Shelli-Harty.png' width='300' alt='Shelli Ferrell Harty - Buyer Specialist' />
                          </Col>
                          <Col md={12} lg={8} xxl={9}>
                            <Stack gap={1}>
                              <h4 className='blue-color'>Shelli Ferrell Harty, eXp Realty - Buyer Specialist</h4>
                              <span><strong>Email:</strong> <a href='mailto:Shelli@RESLuxuryGroup.com?subject=Website%20Contact' aria-label='Click to email Shelli Harty of RES Luxury Group, LLC' title='Click to email Shelli Harty of RES Luxury Group, LLC'>Shelli@RESLuxuryGroup.com</a></span>
                              <span><strong>Phone:</strong> <a href='tel:+14436243605' aria-label='Click to automatically dial Shelli Harty from mobile device' title='Click to automatically dial Shelli Harty from mobile device'>443.624.3605</a></span>
                              <span><strong>Bio:</strong> Shelli relocated to Citrus County in 2017 from Maryland to be close to her family. She is happily married and a grandma of three crazy kids.
                              She has witnessed the growth in the county over the years, as she has been visiting family in the area since the 70s and she is excited to be a part of the future growth in this community.
                              After buying and selling a house at the same time in different states, she knew it was time to pursue her childhood dream. She knew she has what it takes to help others the way her Realtors helped her.
                              Shelli has always wanted to be a real estate sales associate. When she was a little girl, she would go to the model homes in her neighborhood with her best friend and pretend like she was selling the house.
                              Shelli brings with her compassion, professionalism and attention to detail from over 25 years working in the medical field. In addition, her positive outlook and sense of humor will help with the stress that can come with finding your new home.</span>
                            </Stack>
                          </Col>
                        </Row>                                                      
                        <Row className='align-items-center py-4'>
                          <Col md={12} lg={4} xxl={3}>
                            <img src='/assets/images/Josh-Squires.png' width='300' alt='Josh Squires - Principal Technologist' />
                          </Col>
                          <Col md={12} lg={8} xxl={9}>
                            <Stack gap={1}>
                              <h4 className='blue-color'>Josh Squires, eXp Realty - Principal Technologist</h4>
                              <span><strong>Email:</strong> <a href='mailto:Josh@RESLuxuryGroup.com?subject=Website%20Contact' aria-label='Click to email Josh Squires of RES Luxury Group, LLC' title='Click to email Josh Squires of RES Luxury Group, LLC'>Josh@RESLuxuryGroup.com</a></span>
                              <span><strong>Phone:</strong> <a href='tel:+13524761426' aria-label='Click to automatically dial Josh Squires from mobile device' title='Click to automatically dial Josh Squires from mobile device'>352.476.1426</a></span>
                              <span><strong>Bio:</strong> Leveraging a rich background in information technology, with specialized degrees in computer sciences and certifications from industry giants like Microsoft™, Cisco™, and CompTIA®, I bring a unique edge to RES Luxury Group. My expertise ensures we harness the full potential of cutting-edge technology, setting our luxury real estate services apart and delivering unparalleled results for both buyers and sellers.</span>
                            </Stack>
                          </Col>
                        </Row> 
                        <Row className='align-items-center py-4'>
                          <Col md={12} lg={4} xxl={3}>
                            <img src='/assets/images/Dina-Didion.png' width='300' alt='Dina Didion - Marketing Manager' />
                          </Col>
                          <Col md={12} lg={8} xxl={9}>
                            <Stack gap={1}>
                              <h4 className='blue-color'>Dina Didion - Marketing Manager</h4>
                              <span><strong>Email:</strong> <a href='mailto:Dina@RESLuxuryGroup.com?subject=Website%20Contact' aria-label='Click to email Dina Didion of RES Luxury Group, LLC' title='Click to email Dina Didion of RES Luxury Group, LLC'>Dina@RESLuxuryGroup.com</a></span>
                              <span><strong>Phone:</strong> <a href='tel:+14843641975' aria-label='Click to automatically dial Dina Didion from mobile device' title='Click to automatically dial Dina Didion from mobile device'>484.364.1975</a></span>
                              <span><strong>Bio:</strong> Dina relocated to Hernando County in the summer of 2024, bringing with her a wealth of experience in safety, environmental, and production management. With a strong track record in overseeing operations and ensuring compliance with safety and environmental regulations, Dina has built a diverse professional background that uniquely positions her to make a positive impact on our team.
                                In addition to her professional expertise, Dina is passionate about giving back to the community. She has dedicated time to volunteering with various youth sports groups, further underscoring her commitment to fostering growth and development both professionally and personally.
                                Dina's combination of hands-on management experience and community involvement makes her a valuable asset to our organization as she works to help us achieve our goals and uphold our standards.</span>
                            </Stack>
                          </Col>
                        </Row>                             
                        <Row className='align-items-center py-4'>
                          <Col md={12} lg={4} xxl={3}>
                            <img src='/assets/images/Tinamarie-Galgano.png' width='300' alt='Tinamarie Galgano - Administrator Assistant' />
                          </Col>
                          <Col md={12} lg={8} xxl={9}>
                            <Stack gap={1}>
                              <h4 className='blue-color'>Tinamarie Galgano - Administrator Assistant</h4>
                              <span><strong>Email:</strong> <a href='mailto:Tinamarie@RESLuxuryGroup.com?subject=Website%20Contact' aria-label='Click to email Tinamarie Galgano of RES Luxury Group, LLC' title='Click to email Tinamarie Galgano of RES Luxury Group, LLC'>Tinamarie@RESLuxuryGroup.com</a></span>
                              <span><strong>Phone:</strong> <a href='tel:+13526975528' aria-label='Click to automatically dial Tinamarie Galgano from mobile device' title='Click to automatically dial Tinamarie Galgano from mobile device'>352.697.5528</a></span>
                            </Stack>
                          </Col>
                        </Row>                                                                                                                                                                       
                        </Card.Text>                                                              
                    </Card.Body>
                  </Card>
              </Col>
          </Row>
      </Container>       
    </StyledSection>
  );
}

  
  export default ContactPage;